import styled from "styled-components";
import {
  AlignmentHorizontalOption,
  TColorId,
  TLinkId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { alignmentOptionsToValue } from "../../settingsPatterns/composeCss";
import {
  convertColorIdToHex,
  convertLinkIdToValues,
} from "../../settingsPatterns/settingsPatterns.methods";

export const StyledCloseDropdown = styled.div<{
  dropdownWidth: string;
  closeDropdownPadding: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  };
  borderSize: string;
  borderColor: TColorId;
  closeDropdownBackgroundColor: TColorId;
  boxPosition: AlignmentHorizontalOption;
  itemsInDropdownPosition: AlignmentHorizontalOption;
  mainLinkStyle: TLinkId;
}>(
  ({
    dropdownWidth,
    closeDropdownPadding,
    borderSize,
    borderColor,
    closeDropdownBackgroundColor,
    boxPosition,
    itemsInDropdownPosition,
    mainLinkStyle,
    theme,
  }) => {
    return `
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: ${alignmentOptionsToValue(boxPosition)};
        cursor: pointer;

        .close-dropdown-container {
          display: flex;
          align-items: center;
          justify-content: ${alignmentOptionsToValue(itemsInDropdownPosition)};
        }

        .close-dropdown-item {
            padding: ${closeDropdownPadding.top} 
            ${closeDropdownPadding.right} 
            ${closeDropdownPadding.bottom} 
            ${closeDropdownPadding.left};
            display: flex;
            justify-content: ${alignmentOptionsToValue(
              itemsInDropdownPosition
            )};
            align-items: center;
        }
       
        .dropdown {
            border-color: ${convertColorIdToHex(
              borderColor,
              theme.colorPalette
            )};
              border-width: ${borderSize};
              background-color: ${convertColorIdToHex(
                closeDropdownBackgroundColor,
                theme.colorPalette
              )};
              min-width: ${dropdownWidth};
        }
    `;
  }
);

export const StyledOpenDropdown = styled.div<{
  dropdownWidth: string;
  dropdownPosition: AlignmentHorizontalOption;
  openDropdownPadding: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  };
  openDropdownBackgroundColor: TColorId;
  itemsInDropdownPosition: AlignmentHorizontalOption;
  linkStyle: TLinkId;
}>(
  ({
    dropdownWidth,
    dropdownPosition,
    openDropdownPadding,
    openDropdownBackgroundColor,
    itemsInDropdownPosition,
    linkStyle,
    theme,
  }) => {
    return `
        position: absolute;
        display: flex;
        width: 100%;
        justify-content: ${alignmentOptionsToValue(dropdownPosition)};
        cursor: pointer;
        
        ul {
            min-width: ${dropdownWidth};
            background-color: ${convertColorIdToHex(
              openDropdownBackgroundColor,
              theme.colorPalette
            )};
            box-shadow: 6px 6px 10px 0px rgba(188, 183, 177, 0.20);
        }

        li {
            padding: ${openDropdownPadding.top} 
            ${openDropdownPadding.right} 
            ${openDropdownPadding.bottom} 
            ${openDropdownPadding.left};
            display: flex;
            flex-direction: column;
            align-items: ${alignmentOptionsToValue(
              itemsInDropdownPosition
            )};           
        }

        li:active {
          background-color: ${convertColorIdToHex(
            convertLinkIdToValues(linkStyle, theme.globalObjects.links).text
              .activeColor,
            theme.colorPalette
          )};
        }
  `;
  }
);

export const StyledDropdownSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  font-size: 14px;
  border-radius: 4px;
  overflow: auto;
  border: 1px solid #cccccc;
  z-index: var(--zindex-dropdown-select-container);
  background-color: white;
  box-shadow: var(--ecp-shadow-card);
  cursor: pointer;
`;
