import React, { ReactNode } from "react";
import { StyledDropdownSelectContainer } from "../../../boxes/BoxDropdown/BoxDropdown.styled";

export interface DropdownSelectContainerProps {
  children: ReactNode;
  isOpen: boolean;
  className?: string;
}

const DropdownSelectContainer: React.FC<DropdownSelectContainerProps> = ({
  isOpen,
  children,
  className,
}) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isOpen && (
        <StyledDropdownSelectContainer
          className={className}
          data-testid="selectDropdown"
        >
          {children}
        </StyledDropdownSelectContainer>
      )}
    </>
  );
};

export default DropdownSelectContainer;
